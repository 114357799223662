import Button from "primevue/button";
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { nhost, module } from "../main";
import { isUserBlocked } from "@/service/blocking";
import { updateRole } from "@/service/roles";
import { useGlobalStore } from "@/stores/global-store";
import { useUserStore } from "@/stores/user-store";

const routes: RouteRecordRaw[] = [
  {
    path: "/:catchAll(.*)",
    redirect: { name: "notFound" },
  },
  {
    path: "/",
    redirect: { name: "c-requests" },
  },
  {
    path: "/",
    meta: {
      requiresAuth: true,
      requiresPickedTenant: true,
    },
    children: [
      {
        path: "/",
        children: [
          {
            path: "/",
            children: [
              {
                path: "History/:table/:id",
                name: "see-history",
                component: () => import("@/views/pages/History.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/",
        meta: {
          allowedRoles: [
            "tenant_admin_co",
            "tenant_admin_only",
            "tenant_admin_bay",
            "c-lawyer-coo",
          ],
        },
        children: [
          {
            path: "dashboard",
            name: "c-dashboard",
            component: () =>
              import("@/views/pages/modules/carrefour/dashboard/Dashboard.vue"),
          },
          {
            path: "cruds",
            children: [
              {
                path: "reasonType",
                name: "c-reason-type",
                component: () =>
                  import(
                    "@/views/pages/modules/carrefour/cruds/ReasonType.vue"
                  ),
              },
              {
                path: "event",
                name: "c-event",
                component: () =>
                  import("@/views/pages/modules/carrefour/cruds/Event.vue"),
              },
              {
                path: "legalBasis",
                name: "c-legal-basis",
                component: () =>
                  import(
                    "@/views/pages/modules/carrefour/cruds/LegalBasis.vue"
                  ),
              },
              {
                path: "store",
                name: "c-store",
                component: () =>
                  import("@/views/pages/modules/carrefour/cruds/Store.vue"),
              },
              {
                path: "user",
                name: "c-user",
                component: () =>
                  import("@/views/pages/modules/carrefour/cruds/User.vue"),
              },
              /*{
                path: "disciplinaryAction",
                name: "c-disciplinary-action",
                exact: true,
                component: () =>
                  import(
                    "@/views/pages/modules/carrefour/cruds/DisciplinaryAction.vue"
                  ),
              },*/
              {
                path: "closure",
                name: "c-closure",
                component: () =>
                  import("@/views/pages/modules/carrefour/cruds/Closure.vue"),
              },
              {
                path: "reprovacao",
                name: "c-reprovacao",
                component: () =>
                  import(
                    "@/views/pages/modules/carrefour/cruds/Reprovacao.vue"
                  ),
              },
              {
                path: "evidence",
                name: "c-evidence",
                component: () =>
                  import("@/views/pages/modules/carrefour/cruds/Evidence.vue"),
              },
              /*{
                path: "colaborator",
                name: "c-colaborator",
                exact: true,
                component: () =>
                  import(
                    "@/views/pages/modules/carrefour/cruds/Colaborator.vue"
                  ),
              },*/
              {
                path: "sla",
                name: "c-sla",
                component: () =>
                  import("@/views/pages/modules/carrefour/cruds/SLA.vue"),
              },
              /*{
                path: "hierarchy",
                name: "c-hierarchy",
                exact: true,
                component: () =>
                  import(
                    "@/views/pages/modules/carrefour/cruds/Hierarchy.vue"
                  ),
              },*/
              {
                path: "automatedAnswer",
                name: "c-automated-answer",
                component: () =>
                  import(
                    "@/views/pages/modules/carrefour/cruds/AutomatedAnswer.vue"
                  ),
              },
              /*{
                path: "tipo-de-solicitacao",
                name: "c-solicitation-type",
                exact: true,
                component: () =>
                  import(
                    "@/views/pages/modules/carrefour/cruds/SolicitationType.vue"
                  ),
                },*/
            ],
          },
        ],
      },
      {
        path: "/",
        children: [
          {
            meta: {
              requiresAuth: true,
              allowedRoles: [
                "tenant_admin_co",
                "tenant_admin_only",
                "tenant_admin_bay",
                "c-lawyer",
                "c-lawyer-coo",
                "c-requester",
              ],
            },
            path: "/",
            children: [
              {
                path: "instructions-for-use",
                name: "instructionsForUse",
                component: () =>
                  import(
                    "@/views/pages/modules/carrefour/instructionsForUse/InstructionsForUse.vue"
                  ),
              },
              {
                path: "requests",
                name: "c-requests",
                component: () =>
                  import("@/views/pages/modules/carrefour/Requests.vue"),
              },
              {
                path: "old-requests",
                name: "c-old-requests",
                component: () =>
                  import("@/views/pages/modules/carrefour/OldRequests.vue"),
              },
              {
                path: "request",
                children: [
                  {
                    path: "create:id",
                    name: "c-create-request",
                    component: () =>
                      import(
                        "@/views/pages/modules/carrefour/request/Create.vue"
                      ),
                  },
                  {
                    path: "manage/:id",
                    name: "c-manage-request",
                    component: () =>
                      import(
                        "@/views/pages/modules/carrefour/request/Manage.vue"
                      ),
                  },
                ],
              },
              {
                path: "requests",
                name: "c-requests",
                component: () =>
                  import("@/views/pages/modules/carrefour/Requests.vue"),
              },

              // {
              //   path: "smart-assistance",
              //   children: [
              //     {
              //       path: "chat",
              //       component: () => import("@/views/pages/modules/smart-assistance/chat/Chat.vue"),
              //     },
              //   ],
              // },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/errors",
    children: [
      {
        path: "/not-found",
        name: "notFound",
        component: () => import("@/components/errors/NotFound.vue"),
        props: {
          Button,
        },
      },
      {
        path: "/under-construction",
        name: "underConstruction",
        component: () => import("@/components/errors/UnderConstruction.vue"),
        props: {
          Button,
        },
      },
      {
        path: "/access-denied-m",
        name: "access-denied-m",
        component: () => import("@/components/errors/AccessDeniedM.vue"),
      },
      {
        path: "/",
        children: [
          {
            path: "/access-denied",
            name: "access-denied",
            component: () => import("@/components/errors/AccessDenied.vue"),
            props: {
              Button,
            },
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  //await nhost.auth.refreshSession()
  const globalStore = useGlobalStore();
  const userStore = useUserStore(); // Instância do store
  if (from?.fullPath && to.fullPath != from?.fullPath)
    globalStore.hideLoading();

  const requiresAuth = to.meta.requiresAuth;
  const requiresPickedTenant = to.meta.requiresPickedTenant;
  const allowedRoles = to.meta.allowedRoles as string[];
  const isAuthenticated = await nhost.auth.isAuthenticatedAsync();
  await nhost.auth.refreshSession();
  const hasPickedTenant = !!nhost.auth.getHasuraClaim("x-hasura-Tenant-Now-Id");

  if (["access-denied-m", "access-denied"].includes(to.name.toString()))
    return next();

  if (requiresAuth && !isAuthenticated) {
    window.location.href = "/auth#/login";
    return;
  }

  const role = await updateRole();
  if (requiresPickedTenant && !hasPickedTenant) {
    const encodedRedirect = encodeURIComponent(
      `/modules/${module}#${to.fullPath as string}`
    );
    window.location.href = `/home#/setup/setup-choice${encodedRedirect}`;
    return;
  }

  if (
    (await userStore.fetchUserBlockedStatus()) &&
    to.fullPath !== "/access-denied-m"
  ) {
    next({ name: "access-denied-m" });
    return;
  }
  if (allowedRoles && !allowedRoles.includes(role as string)) {
    next({ name: "access-denied" });
    return;
  }

  next();
});

export default router;
